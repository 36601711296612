.Footer {
  background-color: #081f32;
  color: #ABABAB;
  padding: 80px 0;
}

.footerContact {
  display: flex;
  padding: 10px;
}


.footerSection {
  width: 33.3%;
  margin: 0 25px;
}
.footerSection p{
  line-height: 1.5;
  margin-bottom: 25px !important;
}
.Footer h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.Footer p {
  font-size: 14px;
}
.faqBtn {
  border: 1px solid #76d4f9;
  padding: 15px;
  font-size: 12px;
  margin: 15px 0 !important;
  border-radius: 10px;
  color: #ABABAB;
  cursor: pointer;
}

.faqBtn:hover {
  color: #ABABAB;
  background-color: #76d4f9;
}

.Footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footerSection .download  img{
  width: 110px ;
  height: 37px !important;
}
.hoho {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;

}

.hoho img {
  width: 100px;
  height: 25px;
  cursor: pointer;
}

.Footer ul li {
  color: #ABABAB;
  font-size: 14px;
  /* font-size: 1rem; */
  margin-bottom: 15px;
  font-weight: 300;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.3s ease;
}
.Footer ul li:hover{
  color: #76d4f9;
}
.socialMedia{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.copyRight{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-size: 14px;

}
@media (max-width: 767px) {
  .footerContact {
    flex-direction: column;
  }

  .footerSection {
    width: 100% !important;
    margin: 0;
    margin-bottom: 30px !important;
  }
}
