.container {
  position: relative;
  height: 250px;
  overflow: hidden;
}

.background-image {
  background: url("../../../until/Image/AdobeStock_143012483.jpg") center/cover
    no-repeat;
}
.background-image-GuideFirstTime {
  background: url("../../../until/Image/AdobeStock_351530010.jpg") center/cover
    no-repeat;
}

.background-image-TransferStudent {
  background: url("../../../until/Image/AdobeStock_345571107.jpg") center/cover
    no-repeat;
}

.background-image-GuideFirstTime,
.background-image-TransferStudent,
.background-image {
  width: 100%;
  height: 100%;
  filter: brightness(0.5);
}

.guideFirstTimeBox {
  padding: 10px 0px;
}
.guideFirstTimeBox h1 {
  font-size: 32px;
  line-height: 1.17em;
  color: #333333;
}
.Collapse h2 {
  font-size: 16px;
  color: #696969;
  font-weight: 500;
  padding: 10px 25px 0px;
  margin-bottom: 0;
}
.guideFirstTimeBox .title {
  font-weight: 200;
}
.centered-text {
  position: absolute;
  color: white;
  text-align: center;
  width: 80%;
  top: 50%;
  font-weight: bold;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 27px;
}

.contact {
  display: flex;
  align-items: center;
  padding: 50px 0px;
}
.contact div {
}
.contact h1 {
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.contact div:first-child {
  flex: 2;
}

.contact div:nth-child(2) {
  flex: 2;
}

.contact div img {
  width: 340px;
  height: 229px;
  margin-top: -70px;
}
.active {
  background-color: #76d4f9;
}
.active h3 {
  color: #fff;
}

.contact p {
  font-size: 15px;
  color: #696969;
}
.transferLink {
  display: flex;
  align-items: center;
}

.transfer-page {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.Guide-Support {
  margin: 50px 0px;
  background-color: #76d4f9;
  padding: 50px;
  border-radius: 10px;
}
.Guide-Support h1 {
  color: #fff;
  margin: 5px 0px 10px 0px;
  font-size: 40px;
  font-weight: 500;
}

.Guide-Support h6 {
  font-size: 16px;
  color: #333;
  line-height: 1;
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 10px;
}
.new-page {
  font-weight: bold !important;
  color: #6ac5f1;
  font-size: 15px;
}
@media only screen and (max-width: 768px) {
  .contact {
    flex-direction: column-reverse;
  }
  .Guide-Support h1 {
    font-size: 22px;
  }

  .Guide-Support h6 {
    margin: 10px 0px;
  }

  .contact div img {
    margin: 20px 0px;
  }
  .contact h1 {
    margin-top: 10px;
    font-size: 25px;
  }
  .guideFirstTimeBox h1 {
    font-size: 26px;
  }
}
