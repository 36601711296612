.cardBox {
  margin: 50px 0px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.card h2 {
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 0;

}
.cardWork h2{
  font-size: 20px;
  color: #333;
}
.cardWork {
  flex: 0 0 30%;
  margin: 10px;
  padding: 25px 0px;
  text-align: center;
  transition: box-shadow 0.3s ease;
}
.cardWork:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-color: #efefef;
}

.cardWork p {
  color: #696969;
  padding: 10px;
  text-align: center;
  margin: 14px 0px 0px;
}

.cardWork img {
  width: 150px;
  height: 150px;
}

.link {
  margin: 25px 0px;
  display: flex;
  justify-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .cardWork {
    flex: 0 0 45%;
  }
}

@media (max-width: 576px) {
  .cardWork {
    flex: 0 0 100%;
  }
}
