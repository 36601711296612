.MakeSureSection-container {
  padding: 50px 0px;
}

.MakeSureSection-container h1 {
  text-align: center;
  font-size: 34px;
  color: #333333;
  font-weight: 600;
}

.count h1 {
  font-size: 45px !important;
  color: #76d4f9;
  margin-bottom: 0;
}
.count p {
  margin-top: 0;
  text-transform: uppercase;
  color: #3f3a64;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.makeSureCount{
  display: flex !important; 
  justify-content: space-evenly;
}
.right-side img{
  width: 100%;
  padding-top:0;
}

.main-content {
  padding: 35px 25px;
  /* padding: 25px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.left-side{
  /* background-color: red; */
  /* display: flex; */
}
.first-section {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  /* text-align: center; */
  /* display: flex;  */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.first-section div {
  margin: 20px 0px;
  padding:0px 15px;
}
.first-section p{
  color: #696969;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px !important;
}
.right-side img{
  padding-top: 100px;
}

@media only screen and (min-width: 768px) {
  .main-content {
    flex-direction: row;
    justify-content: space-between;
  }
  

  .main-content > div {
    width: 45%;
  }
}

@media only screen and (max-width: 768px) {
  .right-side img{
    margin-top: 50px;
    
  }
  .first-section {
    display: flex;
    flex-direction: column;
  }
  .count{
    display: flex;
    flex-direction: column;
  }

  .MakeSureSection-container h1 {
  font-size: 30px;
}
.right-side img{
  width: 100%;
  padding-top:0;
}
}


/* @media only screen and (max-width: 600px) {
  .right-side img{
    margin-top: 50px;
    
  }
  .first-section {
    flex-direction: column;
  }
  .count{
    display: flex;
    flex-direction: column;
  }

  .MakeSureSection-container h1 {
  text-align: center;
  font-size: 36px;
  color: #333333;
}

} */