.count{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
}
.count p{
    font-weight: bold;
}
.studentsPage-content{
    padding: 15px;
}
.studentsPage-content p{
    line-height: 1.5;
}
.count h1{
    font-size: 60px;
    font-weight: bold;
}

h1{
    font-size: 40px;
}