.mainBox {
  display: flex;
  /* font-family: "Open Sans", sans-serif; */
  padding: 50px 0px;
}

.mainBox .left-side {
  flex: 1.5;
}
.mainBox .left-side div {
  border: 0.1px solid #eee;
  margin: 10px 25px;
  transition: 0.3s ease;
  border-radius: 2px;
  cursor: pointer;
}

.activeTab {
  color: #fff !important;
  background-color: #76d4f9 !important;
}
.activeTab h4{
  color: #fff !important;
}
.mainBox .left-side div:hover {
  background-color: #76d4f9;
}

.mainBox .left-side div:hover h4 {
  color: #fff;
}
.mainBox .right-side {
  flex: 3;
  margin: 10px;
}

.left-side h4 {
  padding: 0px 15px;
  color: #696969;
  font-weight: 500;
  font-size: 14px;
}


@media only screen and (max-width: 768px) {
  .mainBox{
    flex-direction: column-reverse;
  }
}
