

@media only screen and (max-width: 768px) {
    .masterBody .mainImg{
        width: 100% !important;
        height: 50% !important;
    }
    .imgSection img{
        height: 50% !important;
        width: 100% !important; 
    }
  }

  .LeftSideMenu {
    padding: 15px;
    /* margin-left: 50px; */
  }
  .LeftSideMenu div{
    display: flex;
    align-items: center;
  }

  .LeftSideMenu div p:hover  {
    color: #20AD96 !important;  
  }
  .LeftSideMenu div p{
    display: flex;
    transition: 0.5s ease;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.78;
    cursor: pointer;
    color: #696969;
}
  
  .LeftSideMenu h2 {
    color:#333;
    font-weight: 400;
    margin-bottom: 9px;
  } 
  .arrowStyle{
    font-size: 20px !important;
    color: #333;
    margin-right: 15px;
    transition: 0.5s ease;
  }
  .arrowStyle:hover{
    color: #20AD96;
  }
  .boxSearch{
    border-radius: 5px;
    padding: 10px;
    background-color: #378fb4;
  }
  .boxSearch h2{
    color: #fff;
    font-weight: 500;
  }


  @media only screen and (max-width: 768px) {
    .masterBody .mainImg{
        width: 100% !important;
        height: 50% !important;
    }
    .imgSection img{
        height: 50% !important;
        width: 100% !important; 
    }
    .LeftSideMenu {
      padding: 15px;
      margin-left: 0;
    }
  }