.ContentCountries{
    border-radius: 2px;
    box-shadow: 0 0 5px #eee;  
    /* background-color: red; */
    /* margin: 0 !important; */
}

.aboutSection{
    /* padding: 10px; */
    /* background-color: red; */
}
.aboutSection h2{
    /* color: green !important; */
}

.aboutSection p{
    /* color: red !important; */
}
.feat-name{
    padding: 50px;
}
.feat-name ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    gap: 25px;
}

.feat-name ul li {
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #1EA7DD;
    margin: 0px;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease-in-out;
}

.feat-name ul li.selected {
    border-bottom: 2px solid #1EA7DD;
    font-weight: bold;
}
.feat{
    padding:0px 45px ;
}

.feat h2{
    color: #333;
    font-size: 32px;
    font-weight: 200;
}
.feat .header{
    font-weight: 600;
    color: #333;
    font-size: 32px;
    margin: 0px 0px 16px;
}

.feat span{
    color: #4D4D4D;
    font-size: 16px;
    line-height: 1.3;
}

.feat div{
    padding: 18px 0px;
}

@media only screen and (max-width: 768px) {
    .feat-name ul li  {
        /* padding: 0px; */
    }
  }
  

/* .feat-name ul li  */