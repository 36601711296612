.uniAppPlatform{
    background-color: #f8f8f8;
    padding: 100px 0px;
}

.uniAppPlatform p{
    color: #333333;
    font-weight: 400;
}

.uniAppPlatform h3{
    font-weight: 700;
}