.shareComCont {
  padding: 50px 0px 100px 0px;
  background-color: #f1fbff;
}
.headerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rightSide {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sideMain {
  text-align: center;
}

.headerName {
  color: #333;
  font-size: 22px;
}
.btnStyle {
  margin-top: 50px;
}

.titleHeader{
  text-align: center;
  /* font-size: 20px !; */
  color: #333;
  margin-bottom: 25px;
}

.btnStyle span {
  padding: 20px 40px;
  font-size: 14px;
  border-radius: 30px;
  color: #fff;
  font-size: 15px;
  background-color: #6ac5f1;
  font-weight: 500;
}

.btnStyle span:hover {
  background-color: #007dae;
  color: #fff;
  cursor: pointer;
}
.title {
  color: #76d4f9;
}

.hover-text {
  transition: all 0.3s ease;
}

.hover-text.hovered {
  transform: translate(-5px, -5px);
}

.shareComCont img {
  width: 60px;
  height: 75px;
  opacity: 40%;
  padding: 25px;
}

.sideMain h2{
  margin-bottom: 0;
  font-size: 22px !important;
}

.sideMain h1{
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 30px !important;
}

@media only screen and (max-width: 768px) {
  .shareComCont img {
    display: none;
  }
  .shareComCont{
    padding: 50px 0px;
  }
  .sideMain .titleHeader{
    font-size: 25px;
  }
}
