.studentsPage-content .mainImage {
  border-radius: 5px;
  width: 100%;
  margin-top: 35px;
}

.studentsPage-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.imageHeader {
  width: 65px;
  height: 65px;
  opacity: 40%;

}


.studentsPage-content h1{
  font-weight: 600;
}
@media only screen and (min-width: 768px) {
  .studentsPage-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .studentsPage-content > div {
    width: 45%;
  }
}

.hover-text {
  transition: all 0.3s ease;
}

.hover-text.hovered {
  transform: translate(-5px, -5px);
}

@media only screen and (min-width: 768px) {
  .studentsPage-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .studentsPage-content > div {
    width: 45%;
  }
}


@media only screen and (max-width: 768px) {
  .imageHeader{
    display: none;
  }
  
.studentsPage-content div:first-child{
  padding: 0px 0px 20px 0px;
}
}

