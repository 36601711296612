.DestinationsAbroad{
    text-align: center;
    padding: 50px 0px;
}
.DestinationsAbroad img{
    width: 100%;
    height: 100%;
}
.DestinationsAbroad h2{
    color: #1ea7dd;
    font-weight: 700;

}
.DestinationsAbroad button{
    border: none;
    background-color: white;
    border: 1px solid #1ea7dd;
    padding: 10px 25px;
    margin: 20px 0px 0px 0px;
    cursor: pointer;
    color: #000;
    border-radius: 50px;
    transition: 0.3s ease;
}

.DestinationsAbroad button:hover{
    background-color: #1ea7dd;
    color: #fff;
}

.imgCountrys{
    display: grid;
    grid-template-columns: repeat(4 , 1fr);
    padding: 25px 0px;
}
@media only screen and (max-width: 768px) {
    .DestinationsAbroad img{
        width: 100%;
    }
    .imgCountrys{
        display: flex;
        flex-direction: column;
        margin: 10px 0px;
    }

    .imgCountrys img{
        margin: 10px 0px;
    }
  }