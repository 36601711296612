.StudyMedicineInGermanyBody .mainImage {
    width:770px !important;
    height: 400px !important;
    border-radius: 5px;
}

.StudyMedicineInGermanyBody .title{
    color: #696969 ;
    font-weight: 500;
    transition: 0.3s ease;
    cursor: pointer;
}

.StudyMedicineInGermanyBody .title:hover{
    color: #20ad96;
}

.SecImage{
    margin: 25px 0px;
    width:300px !important;
    height: 200px !important;
}

.StudyMedicineInGermanyBody ul {
    list-style-type: disc;
    color: #333;
    padding: 0px 15px;
  }
  
  .StudyMedicineInGermanyBody ul li{
    margin: 20px 0px;
    color: #696969;
    font-size: 14px;
    line-height: 1.3;
  }
@media only screen and (max-width: 768px) {
    .StudyMedicineInGermanyBody .mainImage {
        width:100% !important;
        height: 50% !important;
        border-radius: 5px;
    }
  }
  