.main-title {
  text-align: center;
  font-size: 26px;
  color: #333;
}
.parentTheDiv {
  background: transparent linear-gradient(180deg, #76d4f9 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;

}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}
.countryCard h2 {
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
}
.countryCard {
  padding: 20px 0px;
  border: 1px solid #eee;
  text-align: center;
  transition: 0.5s ease;
}
.countryCard:hover {
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  /* border-style: solid; */
  /* border-color: #efefef; */
  /* background-color: red; */
  /* border-width: 1px 1px 1px 1px; */
}

.countryCard p {
  color: #696969;
  padding: 10px;
  text-align: center;
  line-height: 1.4;
  margin: 14px 0px 0px;
}

.countryCard img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.link {
  font-weight: 500;
  font-size: 13px;
  display: flex;
  padding: 5px;
  justify-items: center;
  justify-content: center;
  transition: 0.3s ease;
  cursor: pointer;
}

.link:hover .link-href,
.link:hover .icon-link {
  color: #76d4f9;
}

.link .icon-link {
  font-size: 18px;
  margin-left: 8px;
  cursor: pointer;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn span {
  border: 1px solid #6ac5f1;
  padding: 15px 30px;
  font-size: 14px;
  transition: 0.3s ease;
  border-radius: 30px;
  color: #6ac5f1;
  margin: 50px 0px;
  cursor: pointer;
}

.btn span:hover {
  background-color: #6ac5f1;
  color: #fff;
}

.parentTheCountry {
  position: relative;
  height: 40vh !important;
  /* border-bottom: 8px solid #378fb4; */
}

.backgroundImageMainCountry {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: url("../../../src//until/Image/StudyDestination.jpg") center/cover
    no-repeat !important;
  filter: brightness(0.7);
}

.CountryOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
}

.CountryTextt {
  position: absolute;
  top: 35%;
  color: #fff;
  padding: 15px;
}
.CountryTextt h1 {
  text-align: center;
  padding: 0px 16px;
  /* color: #333 !important; */
  /* color: #ebebeb; */
  font-size: 48px;
  font-weight: 600;
}

.CountryTextt p {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 900;
  /* color: #d8d8d8; */
}
.sssss {
  display: flex;
  /* width: 75%; */
  margin: 20px 0px;
  gap: 10px;
}

@media only screen and (max-width: 768px) {
  .sssss {
    width: 100%;
    flex-direction: column;
  }
  .countrySearchBtn {
    width: 40%;
    margin-top: 15px;
  }
}

.searchBtn {
  background-color: #76d4f9;
  /* padding: 18px 40px; */
  cursor: pointer;
  border-radius: 30px;
  border: none;
  margin-left: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
}
.countrySearchBtn {
  background-color: #1ea7dd;
  border: none;
  color: #fff;
  border-radius: 30px;
  padding: 8px 25px;
  cursor: pointer;
}
.imgCountrySearch {
  display: flex;
}

.image-containerr {
  position: relative;
  cursor: pointer;
  /* overflow: hidden !important; */
  margin: 10px;
}

.image-containerr .overlayy {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-end;
}

.image-containerr .overlay-textt {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  margin: 10px;
}

@media only screen and (max-width: 768px) {
  .imgCountrySearch {
    flex-direction: column;
  }
  .CountryText p {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .gridContainer {
    display: flex;
    flex-direction: column;
  }
}

.city {
  padding: 50px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.city div {
  display: flex;
  align-items: center;
}

.city p {
  cursor: pointer;
  color: #696969;
  transition: 0.3s ease;
}
.countriesIcon {
  color: #696969;
  font-size: 14px !important;
  margin-left: 5px;
  transition: 0.3s ease;
}

.city p:hover {
  color: #000;
  font-weight: 500;
}

.city p:hover + .countriesIcon {
  color: #000 !important;
}
@media only screen and (max-width: 768px) {
  .city {
    display: flex;
    flex-direction: column;
  }
  .CountryTextt {
    top: 20%;
  }
  .parentTheCountry {
    height: 50vh !important;
  }
}

@media only screen and (max-width: 600px) {
  .parentTheCountry {
    height: 75vh !important;
  }
  .CountryTextt h1 {
    font-size: 35px !important;
  }
}
