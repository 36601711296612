.StudentsworldwideBox{
    background-color: #f8f8f8;
    padding: 50px 0px;
    margin-bottom: 25px;
    border-radius: 0% 0% 50% 50% / 0% 0% 25% 25%;
    height: fit-content;
}

/* StudentsworldwideBox */

@media only screen and (max-width: 768px) {
    /* .StudentsworldwideBox {
        border-radius: 50% 50% 50% 50% / 0% 0% 10% 10% !important;
    } */
  }
  