.serviceContact {
  color: #696969;

  text-align: center;
  padding: 50px 0px;
  background-color: #f8f8f8;
  border-radius: 0% 0% 50% 50% / 0% 0% 50% 50%;
}
.countryBox img {
  width: 120px;
  height: 120px;
}
.countryTitle {
  text-align: center;
}
.countryTitle p {
  font-size: 15px;
  line-height: 1.6;
/* color:'red' */
  /* color: #696969; */
}
.countryTitle h1 {
  font-size: 32px;
  margin-bottom: 0;
  margin-top: 0;
  color: #000;
}
.serviceContact h1 {
  font-size: 32px;
  margin-bottom: 0;
  margin-top: 0;
  color: #000;
}

.ExpectBodyBox {
  margin: 50px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;
  grid-auto-rows: minmax(100px, auto);
  text-align: center;
}
.ExpectBodyBox div {
  transition: 0.3s ease;
  padding: 25px 10px;
}
.ExpectBodyBox div:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.ExpectBodyBox h3 {
  margin-bottom: 5px;
  font-weight: 600;
  color: red;
}

.ExpectBodyBox p {
  margin-top: 0;
  color: #696969;
  line-height: 1.2;
}

.futureMedical {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 35px 0px;
  gap: 20px;
}

.futureMedical div {
  cursor: pointer;
  padding: 25px 10px;
  transition: 0.3s ease;
}

.futureMedical div:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 25px 10px;
  background-color: #fff;
}

.futureMedical h3 {
  color: #3f3a64;
  font-weight: 600;
}
.futureMedical p {
  font-size: 15px;
  line-height: 1.6;
  color: #696969;
}
.studentsPage {
  background-color: #f8f8f8;
}
.headerMedicine{
  padding: 100px 0px 0px 0px;
}
.ExpectBody {
  background-color: #f8f8f8;
  padding: 50px 0px;
}
.ExpectBody h1 {
  text-align: center;
  font-size: 34px;
  color: #333;
}

.notSurePart {
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}
.notSurePart div p:first-child {
  color: #76d4f9;
}
.countryBox .gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 2fr);
  gap: 5px;
}

@media only screen and (max-width: 768px) {
  .futureMedical {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
  }
  .countryBox .gridContainer {
    display: flex;
    flex-direction: column;
  }

  .serviceContact {
    border-radius: 0% 0% 25% 25% / 0% 0% 25% 25%;
  }
  .ExpectBodyBox{
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
  }
  .headerMedicine {
    /* padding: 100px 0px 0px 0px; */
  }
}
