.customCarousel {
  padding: 50px 0px;
  background-color: #fff;
}
.customCarousel img {
  width: 150px;
  height: 75px;
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  cursor: pointer;
}

.custom-arrow.prev {
  left:-25px;
  z-index: 1000;
  font-size: 30px;
  font-weight: bold;
}

.custom-arrow.next {
  right: 0;
  font-weight: bold;
  font-size: 30px;
}

@media only screen and (max-width: 768px) {
  .custom-arrow.next {
    display: none;
  }
  .custom-arrow.prev {
    display: none;
  }
}
