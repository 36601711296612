.parentTheDiv {
  position: relative;
  height: 45vh;
  /* border-bottom: 8px solid #1ea7dd; */
}

.backgroundImageCountry {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: url("../../../../src/until/Image/JPG/AdobeStock_487411238.jpeg")
    center/cover no-repeat;
  filter: brightness(0.6);
}

.CountryOverlay {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
}

.CountryText {
  position: absolute;
  top: 25%;
  /* width: 60%; */
  padding: 15px;
}
.CountryText h1 {
  margin-bottom: 16px;
  font-size: 48px;
  color:'#333' !important;
  /* color: #fff; */
  font-weight: 400;
}

.CountryText p {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  /* color: #fff; */
}
.sssss {
  display: flex;
  width: 75%;
  margin: 20px 0px;
}

@media only screen and (max-width: 768px) {
  .sssss {
    width: 100%;
    flex-direction: column;
  }
  .searchBtn {
    width: 40%;
    margin-top: 15px;
  }
  .CountryText p {
    font-size: 20px;
  }
  .CountryText {
    top: 12.5%;
    /* width: 100%; */
  }
  .parentTheDiv {
    /* height: 50vh; */
    height: 80vh;
  }
}

.searchBtn {
  background-color: #76d4f9;
  padding: 18px 20px;
  cursor: pointer;
  border-radius: 30px;
  border: none;
  margin-left: 5px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  /* border: 1px solid #378fb4;
  color: #fff;
  border-radius: 25px;
  transition: 0.4s;
  font-size: 18px;
  font-weight: 400;
  margin-right: 16px;
  background: #378fb4;
  cursor: pointer;
  padding: 5px 25px; */
}
