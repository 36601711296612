.bodyNavBar{
}

.main {
  box-shadow: 0 40px 50px rgba(0, 0, 0, 0.5);
}

.activeNav {
  border-bottom: 1px solid #76d4f9;
  border-width: 1px;
  color: #76d4f9 !important;
  font-weight: bold;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  color: white;
}
.bodyNavBar span {
  cursor: pointer;
}


.bodyNavBar span:first-child:hover {
  color: #76d4f9 !important;
  font-weight: 400;
}
.bodyNavBar .btn {
  padding: 3px 18px !important;
  transition: 0.3s ease;
  font-size: 14px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid #76d4f9;
}

.bodyNavBar .btn:hover {
  background-color: #76d4f9;
  color: #fff !important;
}
.bodyNavBar .navBarLink {
  padding: 10px;
  transition: 0.3s ease;
  font-size: 15px !important;
}

.bodyNavBar .navBarLink:hover {
  font-weight: 400;
  color: #76d4f9 !important;
}

.logo img {
  width: 120px;
  height: 50px;
}

.pages {
  display: flex;
  gap: 20px;
  align-items: center;
}

a {
  text-decoration: none;
}

.pages.show {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #333;
  padding: 10px;
}

.burger-menu-container {
  position: relative;
  display: inline-block;
}

.burger-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.4s;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 150px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  color: #696969;
  display: none;
  border-bottom: 2px solid #76d4f9;
}

.dropdown-menu:hover {
  color: black;
}

.burger-menu-container:hover .dropdown-menu,
.dropdown-menu:hover {
  display: flex;
  flex-direction: column;
}

.dropdown-menu div {
  padding: 8px;
  cursor: pointer;
}

.burger-menu:hover {
  transform: none;
}

.sideBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.menuSidebar {
  height: 100%;
  background-color: #1ea7dd;
  padding: 10px 15px;
}

.menuSidebar .menuNavBarLink {
  color: #fff;
  display: block;
  font-weight: 300;
  padding: 25px 0px;
}
.menuSidebar .burgerSideMenu {
  display: flex;
  padding: 15px 0px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.smallMedia {
  display: none !important;
}
.smallMedia div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropMenu {
  position: relative;
  padding: 10px;
}
.mainDropMenu {
  position: absolute;
  top: 100%;
  left: 10px;
  /* margin-top: 5px; */
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  width: 223px;
  /* height: 150px; */
  border-bottom: 4px solid #76d4f9;
  z-index: 1000;
}

.dropMenu p {
  transition: 0.3s ease;
  letter-spacing: 0em;
  line-height: 1.38;
  color: #696969;
  font-weight: 400;
  font-size: 15px !important;
  cursor: pointer;
}

.dropMenu p:hover {
  color: #333;
  font-weight: 500;
}

.visible {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.visible p {
  font-size: 14px;
  color: #fff;
}

.hidden {
  opacity: 0;
  transition: opacity 0.3s ease;
}
@media (max-width: 768px) {
  .burger {
    display: block;
    /* color: red; */
  }
  .smallMedia {
    padding: 10px;
    display: block !important;
  }
  .navbar {
    display: none;
  }
}


@media only screen and (max-width: 922px) {
 .pages{
  gap: 5px;
  font-size: 12px !important;
 }
 .bodyNavBar .navBarLink {
  padding: 5px;
  font-size: 15px !important;
}
.bodyNavBar .btn {
  padding: 3px 10px !important;
}

}