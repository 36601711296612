.EasierWithUniApp {
  background-color: #f1fbff !important;
  padding: 25px 0px;
}
.download {
  display: flex;
}
.download img {
  height: 35px;
}
.download a:nth-child(2) {
  margin: 0px 10px;
}
.EasierWithUniApp p{
  color:#696969;
  line-height: 1.5;
}
 .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f1fbff !important;
}

.content div:nth-child(2) img{
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .content {
    flex-direction: row;
    justify-content: space-between;
  }

  .content > div {
    width: 40%;
  }
}


@media only screen and (max-width: 768px) {
  .content div:nth-child(2) img{
    /* display: none; */
  }
}




@media only screen and (max-width: 600px) {
  .content  {
    flex-direction: column-reverse;
  }
  
  .content > div {
    margin: 20px 0px;
  }
}

.title {
  color: #76d4f9;
}
