.platFormPart{
   padding: 50px 0px;
   background-color: #f8f8f8;
   text-align: center;
}

.platFormPart h1{
    font-size: 34px;
    color: #333;
    text-align: center;
}
.platformBox{
    margin: 50px 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    grid-auto-rows: minmax(100px, auto);
}

.platformBox div{
    padding: 25px 10px;
    transition: 0.3s ease;
}
.platformBox div:hover{
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.platformBox h3{
    margin-bottom: 5px;
    font-weight: 600;
}

.platformBox p{
    margin-top: 0;
    color: #696969;
    line-height: 1.2;
}



@media only screen and (max-width: 768px) {
   .platformBox{
    display: flex;
    flex-direction: column;
   }
  }
  