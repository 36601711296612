.articleCardContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* gap: 20px; */
  }
  
  .articleCard {
    border-radius: 5px;
    background-color: #fff;
    margin: 20px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .articleCard img:hover,
  .mainArticle .articleCard img:hover {
    transform: scale(1.1);
    overflow: hidden;
  }
  
  .articleCard img {
    cursor: pointer;
    transition: 0.3s ease;
    border-radius: inherit;
    width: 100%;
    /* width: 279px !important; */
    height: 300px;
    object-fit: cover; 
    overflow: hidden;
  }
  
  .mainArticle p {
    transition: 0.3s ease;
    /* padding: 15px; */
    text-align: left;
    cursor: pointer;
    color: #333;
    font-weight: bold;
    font-size: 18px;
  }
  
  .articleCard .articleContact {
    padding: 20px;
    width: fit-content;
  }
  
  .articleContact p {
    margin: 0 !important;
  }
  
  .articleContact p:first-child {
    font-size: 14px;
    margin: 0 !important;
    color: #696969;

    margin-bottom: 0 !important;
  }

   
  .articleCard p:last-child {
    font-size: 22px;
    margin-top: 10px !important;
    
  }
  
  .mainArticle p:hover {
    color: #76d4f9;
  }
  
  .mainArticle .ContainerCard:first-child {
    padding: 10px;
  }
  
  .mainArticle .ContainerCard:first-child p {
    color: #696969;
    font-weight: 300;
    transition: 0.3s ease;
    font-size: 17px;
  }
  
  .mainArticle .ContainerCard:first-child p:hover {
    color: #76d4f9;
    
    
  }
  
  @media only screen and (max-width: 768px) {
    .articleCardContainer {
      display: flex;
      flex-direction: column;
    }
  
    .articleCard img {
      width: 100%;
    }
  }

  .articlesContact h3{
    font-size: 34px;
    color: #333;
    margin-bottom: 9px;
    /* color: '#333', fontSize: '34px', marginBottom: '9px' */
  }

  .articlesContact span{
    color: #696969 !important;
  }

  .articlesContact a{
    color: #76d4f9;
    /* font-weight: bold !important; */
    /* border-bottom: 2px solid #76d4f9; */
  }
 
  .articlesContact img{
    width: 300px !important;
    height: 200px !important;
    margin: 50px 0;
  }