.headerCity {
  background: transparent linear-gradient(180deg, #76d4f9 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  height: 40vh;
  display: flex;
  align-items: center;
}

.headerCity h1 {
  margin-bottom: 16px;
  font-size: 48px;
  color: black;
  font-weight: 400;
}

.contactCity h2 {
  font-weight: 500;
}

.cityImage {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  /* padding: 25px 0px; */

}

.imageContainer {
  position: relative;
  margin: 0 10px;
  cursor: pointer;
}

.imageContainer img {
  border-radius: 5px;
}

.full-overlay,
.imageContainer .overlays {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  padding: 10px;
  overflow: hidden;
  box-sizing: border-box;
}

.full-overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.imageContainer .overlays {
  background-color: rgba(0, 0, 0, 0.8) !important; 
  font-size: 12px;
  width: 100%;
  /* border-radius:0px 0px 25px 25px; */
}

@media only screen and (max-width: 768px) {
  .headerCity h1 {
    font-size: 32px;
  }
  .imageContainer{
    margin: 25px 0px;

  }

  .contactCity h2 {
    font-size: 22px;
  }
  .cityImage {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
}

@media only screen and (max-width: 700px) {

}

