.headerName {
  text-align: center;
  font-size: 34px !important;
  color: #333;
  padding: 25px 0px;
}

.headerName span {
  color: #76d4f9;
}

.Secrets-of-Studying {
  background-color: #f1fbff;
  padding-top: 50px;
}
.flex-Card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0px;
}
.articlesSectionCard{
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(5, 1fr);
}
.articlesSectionCard img{
  width: 100%;
}


.ContainerCard .card-text {
  padding: 0px 20px;
}
 .card-text p:first-child:hover{
  color: #76d4f9;
  cursor: pointer;
}
.card-text p:first-child {
  color: #696969;
  font-size: 14px;
  font-weight: 500;
  transition: 0.3s ease;
}
.card-text p:nth-child(2) {
  font-weight: 700;
  color: #3f3a64;
  transition: 0.3s ease;
}

 .card-text p:nth-child(2):hover{
  color: #76d4f9;
  cursor: pointer;
}
.ContainerCard img {
  width: 100%;
  
}

.more-article {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px;
}

.more-article span {
  padding: 15px 30px;
  font-size: 14px;
  border-radius: 30px;
  transition: 0.3s ease;
  color: #6ac5f1;
  border: 0.5px solid #6ac5f1;
}

.more-article span:hover {
  background-color: #6ac5f1;
  color: #fff;
  cursor: pointer;
}


@media only screen and (max-width: 992px) {
  .articlesSectionCard{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}


@media only screen and (max-width: 768px) {

  .articlesSectionCard{
    display: flex;
    flex-direction: column;
  }
  .flex-Card .ContainerCard:first-child,
  .flex-Card .ContainerCard:nth-child(2),
  .flex-Card .ContainerCard:last-child {
    width: auto;
  }
  .flex-Card {
    flex-direction: column;
  }
}

.ContainerCard {
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
  margin: 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* background-color: red; */
  /* max-height: 320px; */
}

.textBottom {
  position: absolute;
  bottom: 30px;
  font-size: 65px;
  left: 10px;
  width: 100%;
  color: #fff;
}


.textBottom h1{
  transition: .3s ease !important; 
  font-weight: 500; 
  margin-top: 0 !important;
  cursor: pointer;
  font-size: 17px;
}
.textBottom p{
  font-size: 14px !important;
  color: #fff !important;
  margin-bottom: 0 !important;
}

.textBottom p:hover{
  color: #76d4f9 !important;
}

.textBottom h1:hover{
  color: #76d4f9 !important;
}
