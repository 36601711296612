.studentsPage-content p{
    color: #696969;
}

.btnContact{
    color: #fff !important;
}

@media only screen and (max-width: 768px) {
   .Container-studentsPage .count div h1{
    font-size: 50px;
   }

   .studentsPage-content div h1{
    font-size: 30px;
   } 
  }