.bodyHeaderUnderNavBar {
}

.header-container {
  border-radius: 0% 0% 50% 50% / 0% 0% 20% 20%;
  background-image: url("../../until/Image/JPG/bg.jpg");
  background-size: cover;
  overflow: hidden;
  background-position: center;
  position: relative;
  height: 75vh;
  position: relative;
  display: flex;
  align-items: center;
}
.overlayImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.header-text {
  position: relative;
  color: #fff;
  z-index: 3;
  padding: 25px;
}
.mainTitle {
  font-size: 14px;
  line-height: 2;
  width: 500px;
  color: #ccc;
  letter-spacing: 3px;
  font-weight: 700;
}

.des {
  font-size: 16px;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 500;
  line-height: 1.5;
}
.subTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 66px;
  line-height: 1.13em;
}
.headerButton {
  margin-top: 25px;
  border: none;
  display: inline-block;
  border-radius: 25px;
  padding: 15px 40px;
  cursor: pointer;
  background-color: #76d4f9;
  font-size: 14px;
  transition: 0.3s ease;
}

.headerButton:hover {
  background-color: #027aaa;
}

.header-text {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .header-text {
    text-align: center;
    width: 100%;
    padding: 10px 0px;
    margin-top: 25px;
  }
  .mainTitle {
    font-size: 15px;
    margin-top: 25px;
    margin-bottom: 0;
    line-height: 1.5;
    font-weight: bold;
    width: 100%;
  }
  .subTitle {
    font-size: 40px;
    margin: 20px 0px;
  }
  .des {
    font-size: 18px;
    line-height: 1.4;
  }
  .header-container {
    height: 90vh;
  }
  .header-container {
  }
}
@media only screen and (max-width: 922px) {
  .header-container {
  }
}
