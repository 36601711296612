.ContentCountries {
  margin: 50px 0px;
  border-radius: 2px;
  box-shadow: 0 0 25px #eee;
  /* background-color: red; */
}

.feat-name {
  padding: 50px;
}
.feat-name ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  gap: 25px;
}

.feat-name ul li {
  padding: 8px;
  font-size: 1500px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #1ea7dd;
  margin: 0px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s ease-in-out;
}

.feat-name ul li.selected {
  border-bottom: 2px solid #1ea7dd;
  font-weight: bold;
}
.feat {
  padding: 0px 45px;
}

.ContentCountries .feat ul li {
  font-size: 16px !important;
}


.feat span {
  color: #4d4d4d !important;
  font-size: 15px;
  line-height: 1.3;
}

.feat h3,
.feat h2,
.feat h1 , .feat span:last-child {
  font-size: 22px;
  line-height: 1.3;
  color: #333 !important;
  font-weight: 500 !important;
}


.feat ul li {
  color: #4d4d4d !important;
  font-size: 12px !important;
}
