.parentDiv {
  position: relative;
  height: 55vh;
}

.backgroundImagee {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: url("../../../until/Image/AdobeStock_367077254_Editorial_Use_Only.jpg")
  center/cover no-repeat;
filter: brightness(0.7);
}

.overlayy {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
}

.parentDiv .centerText {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.centerText p {
  font-weight: 700;
  font-size: 35px;
}

@media only screen and (max-width: 768px) {
  .centerText p{
    font-size: 20px;
  }
}


