.contactUsOption{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}

.contactBox{
    display: flex;
}

.contactBox h1{
    color: #333;
    font-size: 18px;
}

.contactBox p{
    color: #696969;
}

@media only screen and (max-width: 768px) {
    .contactUsOption {
        display: flex;
      flex-direction: column;
    }
    .contactBox{
        flex-direction: column;
        text-align: center;
    }

    
  }

