.main-title {
  text-align: center;
  font-size: 26px;
  color: #333;
}

.cardC h2 {
  font-size: 25px;
  line-height: 1.3;
  color: #333;
  margin-bottom: 0;
}
.cardC {
  flex: 0 0 30%;
  margin: 10px;
  padding: 18px 8px;
  border: 1px solid #eee;
  border-radius: 5px;
  height: 100%;
  text-align: center;
}
.cardC p {
  color: #696969;
  font-weight: 500;
  font-size: 15px;
}
.cardC img {
  width: 200px;
  height: 59.86px;
  margin: 25px 0px;
}

.link {
  transition: 0.3 ease;
  margin: 25px 0px;
  display: flex;
  justify-items: center;
  justify-content: center;
}

.link span{
  font-size: 14px;
  font-weight: 400;

}

.link:hover{
  color: #76d4f9;
}
@media (max-width: 768px) {
  .cardC {
    flex: 0 0 45%;
  }
}

@media (max-width: 576px) {
  .cardC {
    flex: 0 0 100%;
  }
}
