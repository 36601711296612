.bodyApplyForABachelors {
  padding: 50px 0px;
}
.box {
  background-color: #378fb4;
  border-radius: 25px;
  padding: 20px;
}

.box h2 {
  color: #fff !important;
  font-size: 32px;
  padding-left: 13px;
}

.inputs {
  display: flex;
}

.nameOfCategory {
  color: #fff;
  margin-bottom: 8px;
  margin-top: 32px;
  font-size: 6px;
}

.category {
  background-color: #fff;
  margin: 5px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 15px;
  color: "#000";
}

.categories-container {
  display: flex;
  flex-wrap: wrap;
}

.category {
  flex: 1;
  min-width: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 5px;
  box-sizing: border-box;
  cursor: pointer;
}
.category span:first-child {
  font-size: 14px !important;
}

.category.selected {
  background: #003e6a;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 12px !important;
  border-radius: 25px;
  margin: 4px 2px;
  cursor: pointer;
  transition: 0.4s;
}

.category.not-selected {
  background: #e6f8fe;
  color: #424141;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 12px !important;
  border-radius: 25px;
  margin: 4px 2px;
  cursor: pointer;
  transition: 0.4s;
}

.category:hover {
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.2s;
  color: #fff;
  background-color: #003e6a;
}

.optionn {
  display: flex;
  margin: 20px 0px;
  align-items: center;
}

.optionn span:nth-child(1) {
  border: 1px solid #76d4f9;
  color: #fff;
  border-radius: 25px;
  transition: 0.4s;
  font-size: 18px;
  font-weight: 600;
  margin-right: 16px;
  background: #76d4f9;
  cursor: pointer;
  padding: 10px 41px;
}

.optionn span:nth-child(1):hover {
  border: 1px solid #fff;
  color: #76d4f9;
  border-radius: 25px;
  transition: 0.4s;
  font-size: 18px;
  font-weight: 500;
  margin-right: 16px;
  background: #fff;
  cursor: pointer;
  padding: 10px 41px;
}

.optionn span:nth-child(2) {
  /* text-decoration: none; */
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
}

/* 
.inputs  */
.inputs .input1 {
  width: 40%;
}

.inputs .input2 {
  width: 40%;
  border-radius: 18px !important;
  margin-left: 15px !important;
}

@media only screen and (max-width: 768px) {
  .inputs .input1,
  .inputs .input2 {
    width: 100%;
  }
  .inputs .input2 {
    margin: 15px 0px !important;
  }
  .inputs {
    flex-direction: column;
  }
  .category {
    min-width: 90px;
  }
  .box h2 {
    font-size: 30px;
  }
}
