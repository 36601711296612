.AdvisoryCardBody {
  padding-bottom: 50px;
}
.AdvisoryCard {
  position: relative;
  text-align: center;
  max-width: 500px;
  margin: 0px 10px;
  overflow: hidden;
}

.AdvisoryCard img {
  max-width: 100%;
  width: 150px;
}
  
.AdvisoryCard:hover .overlay {
  opacity: 1;
}

.AdvisoryCard:hover .image-container {
  transform: scale(1.1);
}

.overlayAdvisoryCard {
    position: absolute;
    left: 15px;
    border-radius: 3px;
    bottom: 100px;
    width: 100px;
    height: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .AdvisoryCard:hover .overlayAdvisoryCard {
    opacity: 1;
  }
@media only screen and (max-width: 768px) {
  .AdvisoryCard img {
    width: 100%;
  }

  .AdvisoryCard:hover .image-container {
    transform: none;
  }
  .overlayAdvisoryCard {
    display: none;
  }

}