.tabsSearch{
  display: flex;
  justify-items: center;
  flex-wrap: wrap;
  margin: '5px 0';
}
.DataPassed{
  flex:1;
  height: 75px;
  overflow-y: auto;
  margin-left: 25px;
}
.ProgramContainer {
  padding: 100px 0px;
}
.SearchContainer {
  padding: 100px 0;
}
.ProgramContainer img {
  width: 100%;
}
.SearchContainer h2 {
  color: #212529;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
}

.viewDataContainer .ContainerMainImg {
}
.viewDataContainer .mainImg {
  padding: 8px 0;
  margin-right: 25px;
  width: 200px;
  height: 100%; 
  object-fit: cover; 
}

.viewDataContainer .degreeCard{
  width: 100%;
}

.viewDataContainer .degreeCard h5 {
  margin-bottom: 0;
  cursor: pointer;
}
.searchContent {
  background-color: #eee;
  margin-top: 25px;
}
.flex-container {
  display: flex 
}
.flex-container .filter {
  background-color: #fff;
  position: relative;
  padding: 15px;
  width: 230px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  margin-top: 5px;
}

.flex-container .filter p {
  font-weight: 600;
  font-size: 16px;
}
.mainViewData {
  width: 75%;
  /* padding: 25px; */
}
.viewData {
  background-color: #fff;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0px 30px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
}

.flex-container .viewData h4 {
  margin-bottom: 0;
  cursor: pointer;
  font-size: 17px;
  color: #212529;
}
.flex-container .viewData h5 {
  font-weight: 600;
  font-size: 15px;
  color: #696969;
  margin-top: 5px;
}
.flex-container .viewData p {
  color: #696969;
  font-size: 14px;
}

.mainViewData img {
  width: 250px;
  height: 100px;
  padding: 8px 0;
}
.viewDataContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* background-color: red; */
}
.viewData .data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.viewData .data div {
  margin: 10px 0px;
  min-width: 130px;
  max-width: 130px;
}

.viewData .data div.btnCard p {
  border: 1px solid #76d4f9;
  text-align: center;
  border-radius: 15px;
  padding: 5px 8px;
  cursor: pointer;
  transition: 0.3 ease;
}

.viewData .data div.btnCard p:hover {
  background-color: #76d4f9;
  color: #fff;
}

.mainSearch .filterIcon {
  display: none;
  font-size: 35px;
  color: #76d4f9;
  cursor: pointer;
}

.CenterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.ProgramContainerBox {
  background-color: #fff;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 32px;
  margin: -64px 0px 0px 0px;
  border: 1px solid #eee;
  display: flex;
  justify-content: space-evenly;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dataOption {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
}
.proram-title-degree-institute {
  width: 600px;
}
.ProgramContainerBox img {
  width: 150px;
  height: 75;
}
.btnWithImage {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.applyNow {
  text-align: center;
}
.applyNow p {
  border-radius: 10px;
  font-size: 14px;
  padding: 5px 20px;
  border: 1px solid #76d4f9;
  cursor: pointer;
  transition: 0.3s ease;
}

.applyNow p:hover {
  background-color: #76d4f9;
  color: #fff;
}

.proram-title-degree-institute p {
  color: #696969;
  /* font-weight: 200; */
  font-size: 14px;
  margin-left: 10px;
}

.proram-title-degree-institute h1 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px;
  position: relative;
}

.proram-title-degree-institute h1::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #76d4f9;
}
.proram-title-degree-institute h5:first-child {
  font-weight: 500;
  color: #696969;
  margin-top: 10;
  margin-bottom: 0px;
}
.proram-title-degree-institute h5:nth-child(2) {
  color: #696969;
  margin-top: 10px;
  font-weight: 500;
}

/* general Tab */

.general-box {
  display: flex;
  padding: 0px 25px;
  align-items: center;
  background-color: #fff;
  margin: 20px;
  border-radius: 15px;
}
.general-box:nth-child(even) {
  background-color: rgba(109, 207, 246, 0.2);
}
.tabsInGeneral {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 15px;
  color: #000;
  font-size: 200px;
  min-width: 20px;
  /* min-height: 20px; */
  height: 20px;
}

.general-data {
  margin-left: 25px;
}

.general-data h5 {
  margin-bottom: 0;
  color: #696969;
  position: relative;
}

.general-data h5::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #76d4f9;
}

.general-data h3 {
  margin-top: 15px;
  font-weight: 400;
  font-size: 15px;
}

/* AdmissionRequirementsBox */

.AdmissionRequirementsBox {
  background-color: #fff;
  padding: 25px;
  margin: 15px;
  color: #696969;
}

.sidebar-details ul {
  list-style: none;
  margin-left: 0px;
  padding: 0;
}

/* over view section */

.overViewContainer {
  background-color: #f2f2f2;
  padding: 15px;
}
.overViewContainer .overViewContainerContact {
  display: flex;
}
.overViewContainer .option-data {
  width: 30%;
  overflow: hidden;
}

.overViewContainer .option-data div {
  margin-bottom: 20px !important;
  margin: 0 10px;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  /* height: 250px; */
}

.overViewImage {
  overflow: hidden;
}
.overViewContainer .option-data .overViewImg {
  width: 315px;
  height: 140px;
}
.overViewContainer .option-data .details {
  padding: 15px;
}

.overViewContainer .option-data .details h4 {
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
}
.overViewContainer .option-data .details h4::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #76d4f9;
}
.overViewContainer .option-data .details p {
  margin-top: 10px;
}

.overViewContainer .details-box {
  padding: 15px;
}
.overViewContainer .main-tabs {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
}
.overViewContainer .option-tabs {
  width: 70%;
}
.overViewContainer .tabs-name {
  margin: 0px 20px;
  /* display: flex; */
}

.overViewContainer .details-box h4 {
  /* padding: 15px; */
  position: relative;
}

.overViewContainer .details-box h4::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #76d4f9;
}

.overViewContainer .details-box p {
  position: relative;
}

.overViewContainer .details-box p::after {
  content: "";
  position: absolute;
  top: 2px;
  left: -5px; /* Adjust the left position to center the line */
  width: 2px; /* Adjust the width of the line as needed */
  height: 12px;
  background-color: #76d4f9; /* Adjust the color of the line as needed */
  transform: translateX(-50%); /* Center the line horizontally */
}

.tabs-name {
  cursor: pointer;
  color: #696969;
  margin-right: 10px;
}

.option-data h4 {
  color: #000;
  /* border-bottom: 1px solid red; */
  font-weight: 400;
  font-size: 18px;
}
.option-data div {
  /* margin: 20px 0; */
}
.option-data p {
  font-weight: 400;
  font-size: 14px;
  /* border-left: 5px solid #76d4f9; */
}

.option-data .last {
  color: #76d4f9 !important;
  margin-bottom: 20px;
}

.option-data span {
  border: 1px solid #76d4f9;
  text-align: center;
  border-radius: 15px;
  padding: 5px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease;
}
.option-data span:hover {
  background-color: #76d4f9;
  color: #fff;
}

.active-tab {
  border-bottom: 3px solid #76d4f9;
}

.Tuition {
  background-color: #fff;
  padding: 20px;
  height: 250px;
  margin: 10px;
  border-radius: 10px;
}

.Overview {
  background-color: #fff;
  padding: 20px;
  /* height: 400px; */
  margin: 10px;
  border-radius: 10px;
  color: #696969;
}
.Overview .headerOverView , .Overview .subHeaderOverView {
  position: relative;
  color: #000 !important;
  font-size: 22px;
  margin-top: 35px !important;
  margin-bottom: 50px;
  font-weight: 300;
}
.Overview .scrollView {
  height: 400px;
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.Overview .headerOverView::after , .Overview .subHeaderOverView::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #76d4f9;
}

.Overview h3 {
  color: #333;
}

/* Related Start  */
.Related-Programs {
  padding: 50px 0;
}


.Related-Programs h2 {
  margin: 0 0 8px;
  font-weight: 600;
  position: relative;
}

.Related-Programs h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #76d4f9;
}

.Related-Programs-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 25px 0px;
  gap: 20px;
}
.degree-box {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 20px;
}
.degree-box h1 {
  font-size: 14px;
  margin-bottom: 0;
}
.degree-box h5 {
  font-size: 12px;
  color: #696969;
  margin-top: 10px;
  margin-bottom: 35px;
}
.degree-box p {
  font-size: 14px;
  margin-left: 10px;
  margin-top: 0px;
}

.readMore p {
  transition: 0.3s ease;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  margin: 0;
  display: inline-block;
  padding: 5px 20px;
  border: 1px solid #76d4f9;
}

.readMore p:hover {
  background-color: #76d4f9;
  color: #fff;
}
.center-container {
  width: 50% !important;
  margin: 0 0 50px;
  margin-top: 50px;
}

.carousel-item {
  text-align: center;
}
.carouselTabs img {
  width: 100% !important;
  margin: 0 10px;
  height: 100% !important;
}

.flex-container {
  display: flex;
}

aside {
  position: sticky;
  top: 0;
  width: 25%;
  height: 100vh;
  margin-bottom: 20px;
  overflow-y: auto;
}


main {
  padding-left: 20px;
  flex-grow: 1;
}
@media only screen and (max-width: 768px) {
  .Related-Programs-box {
    display: flex;
    flex-direction: column;
  }
  .overViewContainerContact {
    flex-direction: column;
  }
  .overViewContainerContact .option-data {
    width: 100%;
  }
  .overViewContainerContact .option-tabs {
    width: 100%;
  }

  .overViewContainer .option-data .overViewImg {
    width: 100%;
    height: 140px;
  }
  .overViewContainer .tabs-name {
    display: block;
    margin: 10px;
  }
  .option-tabs {
    margin: 0px;
  }
  .overViewContainer .main-tabs {
    padding: 20px 20px;
    margin: 10px 0px;
  }
  .ProgramContainerBox {
    flex-direction: column;
    width: 300px;
  }
  .dataOption {
    display: block;
  }
  .proram-title-degree-institute h1 {
    font-size: 18px;
    width: 250px;
  }
  .lastICON {
    margin-left: 0 !important;
  }
  
}

@media only screen and (max-width: 890px) {
  .tabsSearch{
    display: none;
  }
.ContainerMainImg{
  margin: 20px 0;
}
  .viewDataContainer .mainImg {
    /* width: 500px; */
    /* height: 150px; */
  }
  aside {
    width: auto;
    position: relative;
    top: 10px;
    height: auto;
  }

  .flex-container  .filter{
    margin: 15px 0 !important;
  }

  .btnWithImage{
    margin-top: 25px;
    align-items: flex-start;
  }
  .flex-container  {
    flex-direction: column;
  }
  .flex-container  .mainViewData {
    width: auto;
  }
  .flex-container  .filter {
    width: auto;
    margin: 0px 20px;
  }

  .viewData .data {
    display: block;
  }
  .viewDataContainer {
    flex-direction: column;
  }
  .mainSearch .filterIcon {
    display: block;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* For Webkit-based browsers (Chrome, Safari) */
/* You can add more styles for other vendor prefixes as needed */
/* Add this CSS to your stylesheet */

/* Track (the area the thumb moves in) */
::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar thumb */
  border-radius: 6px; /* Set the border radius of the scrollbar thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}

/* Track on hover */
::-webkit-scrollbar-track:hover {
  background-color: #f0f0f0; /* Set the color of the scrollbar track on hover */
}

/* Handle when the scrollbar is active (being dragged) */
::-webkit-scrollbar-thumb:active {
  background-color: #fff; /* Set the color of the scrollbar thumb when active */
}

/* Track when the scrollbar is active (being dragged) */
::-webkit-scrollbar-track:active {
  background-color: #fff; /* Set the color of the scrollbar track when active */
}

/* Corner (the intersection of the scrollbar track and thumb) */
::-webkit-scrollbar-corner {
  background-color: #fff; /* Set the color of the scrollbar corner */
}



