
.parentTheCountry {
    position: relative;
    height: 55vh;
    /* border-bottom: 8px solid #378fb4; */
  }
  
  .backgroundImageMainCountry {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url("https://images.pexels.com/photos/5905703/pexels-photo-5905703.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") center/cover no-repeat;
    filter: brightness(0.2);
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
  }
  
  .inputContainer > * {
    margin-bottom: 16px;
  }
  
  @media only screen and (min-width: 769px) {
    .inputContainer {
      flex-direction: row;
    }
  
    .inputContainer > * {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
  
  .BachelorsDegreesText {
    position: absolute;
    top: 25%;
    color: #fff;
    padding: 15px;
  }
  
  .BachelorsDegreesText h1 {
    color: #ebebeb;
    font-size: 48px;
    font-weight: 600;
  }
  
  .TextField-root {
    background-color: #fff;
    border-radius: 25px;
    outline: none;
    border: none;
    color: #696969;
    width: 450px !important;
    transition: border 0.3s;
    width: 100%;
  }
  
  .TextField-root:last-child {
    margin-right: 0;
    margin-left: 10px;
  }
  
  .submit {
    display: inline-block;
    border: 1px solid #76d4f9;
    color: #fff;
    margin-top: 25px;
    border-radius: 25px;
    transition: 0.4s;
    font-size: 16px;
    padding: 15px 40px;
    margin-right: 16px;
    background: #76d4f9;
    cursor: pointer;
    font-weight: 600;
    transition: 0.3 ease;
  }
  
  .submit:hover,
  .submitCard:hover {
    background-color: #fff;
    color: #76d4f9;
  }
  .submitCard {
    display: inline-block;
    border: 1px solid #76d4f9;
    color: #fff;
    margin-top: 10px;
    border-radius: 25px;
    transition: 0.4s;
    font-size: 15px;
    padding: 10px 25px;
    background: #76d4f9;
    cursor: pointer;
    font-weight: 400;
    transition: 0.3 ease;
  }
  
  .degreesBy {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 25px 0px;
  }
  
  .degreesBy div {
    background-color: #378fb4;
    border-radius: 30px;
    color: #fff;
    padding: 32px;
    position: relative;
    margin: 16px;
  }
  
  .degreesBy div h2 {
    padding: 0 0 18px;
    font-size: 24px;
  }
  .degreesText {
    font-size: 15px;
    font-weight: 600;
    /* cursor: pointer; */
    margin-left: 15px;
  }
  .degreesContact {
    padding: 25px 0px;
  }
  
  .degreesContact div{
    /* background-color: red; */
  }
  
  .degreesContact ul {
    list-style-type: disc;
    color: #333;
    padding: 25px;
  }
  
  .degreesContact ul li{
    margin: 20px 0px;
    color: #696969;
    font-size: 16px;
    line-height: 1.3;
  }
  .degreesContact h1 {
    color: #333;
    font-size: 32px;
    font-weight: 400;
  }
  
  .degreesContact h2 {
    font-weight: 400;
    color: #333;
  }
  .degreesContact span {
    color: #696969;
    font-size: 16px;
    line-height: 1.3;
  }
  
  
  
  .select-box {
    position: relative;
    display: block;
    background-color: #fff;
    border-radius: 25px;
    outline: none;
    border: none;
    margin-left: 10px;
    color: #696969;
    width: 450px !important;
    transition: border 0.3s;
    width: 100%;
  
  }
  
  .select-box__current {
    position: relative;
    z-index: 10000;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    outline: none;
  }
  .select-box__current:focus + .select-box__list {
    opacity: 1;
    -webkit-animation-name: none;
      animation-name: none;
  }
  .select-box__current:focus + .select-box__list .select-box__option {
    cursor: pointer;
  }
  .select-box__current:focus .select-box__icon {
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
  }
  .select-box__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 20px;
    opacity: 0.3;
    transition: 0.2s ease;
  }
  .select-box__value {
    display: flex;
  }
  
  .select-box__input {
    display: none;
  }
  
  .select-box__input:checked + .select-box__input-text {
    display: block;
  }
  
  .select-box__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 15px;
  }
  
  
  .select-box__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    border-radius: 25px;
    overflow: hidden;
    margin-top: 7px;
    opacity: 0;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  }
  
  
  .select-box__option {
    display: block;
    padding: 15px;
    background-color: #fff;
  }
  
  
  .select-box__option:hover, .select-box__option:focus {
    color: #546c84;
    background-color: #fbfbfb;
  }
  
  
  @-webkit-keyframes HideList {
    from {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
    to {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
    }
  }
  
  @keyframes HideList {
    from {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
    to {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
    }
  }
  
  
  @media only screen and (max-width: 769px) {
    .degreesBy {
      display: flex;
      flex-direction: column;
    }
    .parentTheCountry {
      height: 85vh;
    }
  
    .TextField-root {
      width: 100% !important;
      margin: 10px 0px !important;
    }
    .select-box{
      width: 100% !important;
      margin: 10px 0px !important;
    }
    .TextField-root:last-child {
      margin-left: 0;
    }
    .BachelorsDegreesText {
      top: 10%;
    }
  }
  