.active{
    background-color: #76d4f9;
}

.active h4{
    color: #fff 
}
.right-side .Impressum h2{
    font-weight: 600;
}

.right-side .Impressum p{
    color: #696969;
    font-size: 14px;
}

.right-side{
    color: #696969;
}

.ContainerTerms{
    /* padding: 10px 0px; */
    /* background-color: red; */
}

.ContainerTerms p{
    /* color: red; */
    line-height: 1.8;
    color: #696969;
}
.ContainerTerms ul li{
    /* color: red; */
    line-height: 1.8;
    color: #696969;
}